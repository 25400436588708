import {
  collectionRef,
  setDoc,
  useCollectionDataBetter,
  deleteCollection,
} from './firebase'
import { GamePlayer } from '../models/game-players.model'
import { useMemo } from 'react'
import { useUid } from './auth.hooks'

export const gamePlayersCollectionRef = (gameId: string) =>
  collectionRef(['games', gameId, 'players'])

export const gamePlayersRef = (gameId: string, uid: string) =>
  gamePlayersCollectionRef(gameId).doc(uid)

export const useGamePlayers = (gameId: string) => {
  const ref = useMemo(() => gamePlayersCollectionRef(gameId), [gameId])
  return useCollectionDataBetter<GamePlayer>(ref)
}

export const setGamePlayer = async (gamePlayer: Partial<GamePlayer>) => {
  if (!gamePlayer.gameId) throw new Error('No gameId in gamePlayer')
  return setDoc(gamePlayersCollectionRef(gamePlayer.gameId), gamePlayer)
}

export const useGamePlayersWithMe = (gameId: string) => {
  const [uid, uidLoading] = useUid()
  const [gamePlayers, playersLoading] = useGamePlayers(gameId)
  const loading = uidLoading || playersLoading || !uid
  if (!loading) {
    const meAlreadyIn = gamePlayers.find((p) => p.id === uid)
    if (!meAlreadyIn) {
      setGamePlayer({ id: uid, gameId })
    }
  }
  return { gamePlayers, loading }
}

export const deleteGamePlayers = async (gameId: string) => {
  return deleteCollection(gamePlayersCollectionRef(gameId))
}
