import { useParams } from 'react-router-dom'
import { useUid } from '../service/auth.hooks'
import { useState } from 'react'
import { usePlayingCards } from '../service/playing-cards.hooks'
import { useHoldingCard, useGamePlayersWithCards } from './holding-card.hook'

export const useCardGameBasics = () => {
  const { gameId = 'no-game-id' } = useParams()
  const [uid] = useUid()

  const [editing, setEditing] = useState(false)

  const [cards] = usePlayingCards(gameId)
  const cardHolding = useHoldingCard(cards, uid)
  const gamePlayers = useGamePlayersWithCards(gameId, cards)

  return {
    gameId,
    uid,
    editing,
    setEditing,
    cards,
    cardHolding,
    gamePlayers,
  }
}
