import { createMuiTheme, Theme } from '@material-ui/core/styles'
import {
  purple,
  green,
  // indigo,
  orange,
  pink,
  // amber,
  teal,
  blueGrey,
  red,
  // lime,
  yellow,
  blue,
  deepOrange,
} from '@material-ui/core/colors'
import { useState, useEffect } from 'react'

export const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: purple,
    secondary: deepOrange,
  },
})

export const colors = [
  {
    label: 'Red',
    color: red['500'],
  },
  {
    label: 'Pink',
    color: pink['500'],
  },
  {
    label: 'Purple',
    color: purple['500'],
  },
  // {
  //   label: 'Indigo',
  //   color: indigo['500'],
  // },
  {
    label: 'Blue',
    color: blue['500'],
  },
  {
    label: 'Grey',
    color: blueGrey['500'],
  },
  {
    label: 'Teal',
    color: teal['500'],
  },
  {
    label: 'Green',
    color: green['500'],
  },
  // {
  //   label: 'Lime',
  //   color: lime['500'],
  // },
  {
    label: 'Yellow',
    color: yellow['500'],
  },
  // {
  //   label: 'Amber',
  //   color: amber['500'],
  // },
  {
    label: 'Orange',
    color: orange['500'],
  },
]
export const defaultColor = colors[4].color as string

export const themeByPrimaryColor = (color: string = defaultColor) =>
  createMuiTheme({
    palette: {
      type: 'dark',
      primary: { '500': color },
      secondary: deepOrange,
    },
  })

export const useThemeByPrimaryColor = (color: string = defaultColor) => {
  const [theme, setTheme] = useState<Theme>(themeByPrimaryColor(color))
  useEffect(() => {
    setTheme(themeByPrimaryColor(color))
  }, [color])
  return theme as Theme
}
