import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import { Entity } from '../models/entity.model'
import { useCollection, useDocument } from 'react-firebase-hooks/firestore'
import { useEffect, useState, useMemo } from 'react'

const firebaseConfig = {
  apiKey: 'AIzaSyDdpEkPnv5m4ersK32jhTKhYcg3Oyb5EOo',
  authDomain: 'potato-games.firebaseapp.com',
  databaseURL: 'https://potato-games.firebaseio.com',
  projectId: 'potato-games',
  storageBucket: 'potato-games.appspot.com',
  messagingSenderId: '399778268574',
  appId: '1:399778268574:web:75fb91d744b1dfd9a8c28f',
}

export const firebaseApp = firebase.initializeApp(firebaseConfig)

export const waitForLogin = async () => {
  const auth = firebaseApp.auth()
  const credentials = await auth.signInAnonymously()
  const uid = credentials.user?.uid
  return uid
}

export const docRef = (path: string[]) => {
  return firebaseApp.firestore().doc(path.join('/'))
}

export const collectionRef = (path: string[]) => {
  return firebaseApp.firestore().collection(path.join('/'))
}

export const newId = () => {
  return collectionRef(['xxx']).doc().id
}

export const setDoc = async (
  collectionRef: firebase.firestore.CollectionReference,
  doc: Partial<Entity>
) => {
  const newDoc = {
    id: newId(),
    ...doc,
  }
  const docRef = collectionRef.doc(newDoc.id)
  await docRef.set(newDoc)
  return newDoc
}

export const deleteDoc = async (
  collectionRef: firebase.firestore.CollectionReference,
  docId: string
) => {
  const docRef = collectionRef.doc(docId)
  await docRef.delete()
}

export const deleteCollection = async (
  collectionRef: firebase.firestore.CollectionReference
) => {
  const snap = await collectionRef.get()
  const ids = snap.docs.map((d) => d.id)
  await Promise.all(ids.map((id) => deleteDoc(collectionRef, id)))
}

export const useCollectionDataBetter = <T>(query: any): [T[], boolean, any] => {
  const [value, loading, error] = useCollection(query)
  const [data, setData] = useState<T[]>([])
  useEffect(() => {
    const newData = value ? value.docs.map((doc) => doc.data()) : []
    setData(newData as any)
  }, [value])
  return [data, loading, error]
}

export const useDocumentDataBetter = <T>(
  ref: any
): [T | undefined, boolean, any] => {
  const [value, loading, error] = useDocument(ref)
  const data = useMemo(() => value?.data() as T, [value])
  return [data, loading, error]
}
