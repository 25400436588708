import { PlayingCard } from '../models/playing-card.model'
import { useMemo } from 'react'
import { useGamePlayersWithMe } from '../service/game-players.hooks'
import _ from 'lodash'
import { GamePlayerWithCard } from '../models/game-players.model'

export const useHoldingCard = (cards: PlayingCard[], uid: string) => {
  return useMemo(() => cards?.find((card: any) => card?.holdByUserId === uid), [
    cards,
    uid,
  ])
}

export const useGamePlayersWithCards = (
  gameId: string,
  cards: PlayingCard[]
) => {
  const { gamePlayers } = useGamePlayersWithMe(gameId)
  const gamePlayersHoldingCards = useMemo(
    () =>
      _.map(gamePlayers, (gamePlayer) => {
        const holdingCard = _.find(
          cards,
          (c) => c.holdByUserId === gamePlayer.id
        )
        const pinnedCard = _.find(
          cards,
          (c) => c.pinnedToUserId === gamePlayer.id
        )
        return {
          ...gamePlayer,
          holdingCard,
          pinnedCard,
        } as GamePlayerWithCard
      }),
    [gamePlayers, cards]
  )
  return gamePlayersHoldingCards
}
