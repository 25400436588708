import React from 'react'
import { GamePlayerWithCard } from '../../models/game-players.model'
import { User } from '../../models/user.model'
import { setPlayingCard } from '../../service/playing-cards.hooks'
import { useCardGameBasics } from '../../util/card-game.hooks'
import { GameCardHolding } from '../GameCardHolding'
import { PlayerAvatar } from '../PlayerAvatar'
import { PlayingCardStack } from '../PlayingCardStack'
import { SimpleCardStackGrid } from '../SimpleCardStack'

export const GuessMyCard = () => {
  const {
    gameId,
    editing,
    setEditing,
    cards,
    cardHolding,
    gamePlayers,
    uid,
  } = useCardGameBasics()

  const clickOnUser = async (
    otherUser: User,
    otherPlayer: GamePlayerWithCard
  ) => {
    const otherCard = otherPlayer?.holdingCard
    const myCard = cardHolding

    const doSwap = !!otherCard && !!myCard
    const doTake = !!otherCard && !myCard
    const doGive = !otherCard && !!myCard

    if (doSwap) {
      if (!window.confirm(`Swap Cards with Player?`)) return
    }

    if (doTake) {
      if (!window.confirm(`Take Card from Player?`)) return
    }

    if (doTake || doSwap) {
      await setPlayingCard({
        ...otherCard,
        holdByUserId: uid,
      })
    }

    if (doGive || doSwap) {
      await setPlayingCard({
        ...myCard,
        holdByUserId: otherUser.id,
      })
    }
  }

  return (
    <>
      <div
        className="flex-row"
        style={{
          justifyContent: 'center',
          minHeight: 32,
        }}
      >
        {gamePlayers.map((player) => (
          <PlayerAvatar
            style={{ margin: 4 }}
            key={player.id}
            userId={player.id}
            highlighted={!!player.holdingCard}
            secondaryAction={(user) => clickOnUser(user, player)}
          />
        ))}
      </div>
      <SimpleCardStackGrid style={{ height: 300 }}>
        <PlayingCardStack
          stackId="s0"
          enabled={!editing}
          cardHolding={cardHolding}
          allCards={cards}
        />
        <PlayingCardStack
          stackId="s1"
          enabled={!editing}
          cardHolding={cardHolding}
          allCards={cards}
        />
      </SimpleCardStackGrid>
      <GameCardHolding
        gameId={gameId}
        cardHolding={cardHolding}
        editing={editing}
        setEditing={(e) => setEditing(e)}
      />
    </>
  )
}
