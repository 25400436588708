import React from 'react'
import { PlayingCard } from '../models/playing-card.model'
import { PlayingCardEdit } from './PlayingCardEdit'
import { Button } from '@material-ui/core'
import { Add } from '@material-ui/icons'

export interface GameCardHoldingProps {
  gameId: string
  cardHolding?: PlayingCard
  editing: boolean
  setEditing: (editing: boolean) => void
}

export const GameCardHolding = ({
  gameId,
  cardHolding,
  editing,
  setEditing,
}: GameCardHoldingProps) => {
  const newCard = () => {
    setEditing(true)
  }

  if (cardHolding || editing) {
    return (
      <PlayingCardEdit
        onClose={() => {}}
        gameId={gameId}
        cardId={cardHolding?.id || ''}
        editing={editing}
        setEditing={setEditing}
      />
    )
  } else {
    return (
      <div
        className="flex-center"
        style={{
          minHeight: 118,
          margin: '8px 16px',
        }}
      >
        <Button
          variant="contained"
          size="large"
          color="primary"
          startIcon={<Add />}
          style={{
            alignSelf: 'center',
            borderRadius: 26,
          }}
          onClick={newCard}
        >
          Create New Card
        </Button>
      </div>
    )
  }
}
