import React, { useState, useRef, useEffect, useMemo } from 'react'
import { Card, Button, TextField, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { newId } from '../service/firebase'
import {
  setPlayingCard,
  deletePlayingCard,
  usePlayingCard,
} from '../service/playing-cards.hooks'
import { useUid } from '../service/auth.hooks'
import { PlayingCard } from '../models/playing-card.model'

const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
`

export const PlayingCardEdit = ({
  onClose = () => {},
  setEditing = (editing: boolean) => {},
  gameId = '',
  cardId = '',
  editing = false,
}) => {
  const id = useMemo(() => cardId || newId(), [cardId])
  const [uid] = useUid()
  const [text, setText] = useState('')
  const handleTextChange = (evt: any) => {
    setText(evt.target.value)
  }

  const [card] = usePlayingCard(gameId, id)

  const inputRef = useRef(null)

  useEffect(() => {
    const input: any = inputRef?.current
    input?.focus()
  }, [inputRef])

  useEffect(() => {
    setText(card?.text || '')
  }, [card])

  const isValid = () => {
    return !!text
  }

  const save = async () => {
    await setPlayingCard({
      id,
      ...card,
      gameId,
      text,
      holdByUserId: uid,
    })
    setEditing(false)
  }

  const cancel = () => {
    setEditing(false)
  }

  const deleteCard = async () => {
    if (!window.confirm('Really delete?')) return
    await deletePlayingCard(card as PlayingCard)
  }

  const editingTemplate = (
    <>
      <Top>
        <Button size="small" onClick={cancel}>
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={save}
          disableElevation
          style={{ borderRadius: 20 }}
          disabled={!isValid()}
        >
          Done
        </Button>
      </Top>
      <TextField
        id="outlined-multiline-flexible"
        label=""
        placeholder="Think of something great ..."
        multiline
        rowsMax="4"
        value={text}
        onChange={handleTextChange}
        variant="outlined"
        inputProps={{ style: { textAlign: 'center' } }}
        inputRef={inputRef}
        style={{
          // width: 'calc(100% + 8px)',
          width: 'calc(100% - 16px)',
          // margin: '0 -4px -4px'
          margin: '0 8px',
          // padding: '0 4px 4px'
        }}
      />
    </>
  )

  const viewingTemplate = (
    <>
      <Top>
        <Button
          // variant="outlined"
          size="small"
          disableElevation
          style={{ borderRadius: 20 }}
          onClick={deleteCard}
        >
          Delete
        </Button>
        <Button
          variant="outlined"
          size="small"
          disableElevation
          style={{ borderRadius: 20 }}
          onClick={() => setEditing(true)}
        >
          Edit
        </Button>
      </Top>
      <Typography
        variant="body1"
        style={{
          textAlign: 'center',
          padding: '18.5px 14px',
          whiteSpace: 'pre-wrap',
          lineHeight: '1.1875em',
          margin: '1px 0px 0px',
        }}
      >
        {text}
      </Typography>
    </>
  )

  return (
    <Card
      style={{
        // height: 200,
        // width: 'calc(100vw - 32px)',
        minHeight: 102,
        alignSelf: 'stretch',
        borderRadius: 20,
        margin: '8px 16px',
        paddingBottom: 16,
      }}
    >
      {editing ? editingTemplate : viewingTemplate}
    </Card>
  )
}
