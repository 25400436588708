import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { GameSelect } from './GameSelect'
import { GameMain } from './GameMain'

export const AppRouting = () => {
  return (
    <>
      <Switch>
        <Route path="/game/:gameId" component={GameMain}></Route>
        <Route path="/" component={GameSelect}></Route>
      </Switch>
    </>
  )
}
