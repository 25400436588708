import React from 'react'
import { Button, Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import { CopyUrl } from './CopyUrl'
import { useGameSession } from '../service/game-session.hooks'
import { GameSettingGameMode } from './GameSettingsGameMode'
import { deleteGamePlayers } from '../service/game-players.hooks'
import { deletePlayingCards } from '../service/playing-cards.hooks'

export interface GameSettingsDialogProps {
  gameId: string
  open: boolean
  onClose: () => void
}

export const GameSettingsDialog = ({
  gameId,
  open,
  onClose,
}: GameSettingsDialogProps) => {
  const [gameSession, loading] = useGameSession(gameId)

  const canClose = () => {
    return !loading && !!gameSession
  }

  const close = () => {
    if (!canClose()) return
    onClose()
  }

  const deletePlayers = async () => {
    if (!window.confirm(`Really delete all players?`)) return
    deleteGamePlayers(gameId)
  }

  const deleteCards = async () => {
    if (!window.confirm(`Really delete all cards?`)) return
    deletePlayingCards(gameId)
  }

  return (
    <Dialog open={open} onClose={close} fullWidth={true}>
      <DialogTitle>Game Session</DialogTitle>
      <DialogContent className="flex-col">
        <GameSettingGameMode gameId={gameId} />
        <CopyUrl />
        <div className="flex-row" style={{ margin: '8px 0' }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={deletePlayers}
            style={{ flex: 1 }}
          >
            Delete All Player
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={deleteCards}
            style={{ flex: 1, marginLeft: 8 }}
          >
            Delete All Cards
          </Button>
        </div>
        <div className="flex-row" style={{ margin: '16px 0' }}>
          <Button variant="outlined" href="/" style={{ flex: 1 }}>
            Leave Game
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={onClose}
            color="primary"
            style={{ flex: 1, marginLeft: 8 }}
            disabled={!canClose()}
          >
            Continue
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
