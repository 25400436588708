import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { newId } from '../service/firebase'

export const GameSelect = () => {
  const btnStyle: React.CSSProperties = {
    maxWidth: 300,
    width: '100%',
    margin: 16,
    padding: '16px 8px',
    borderRadius: 40
  }

  const newGameId = newId()
  const gameUrl = (gameId: string) => `/game/${gameId}`

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div className="flex-1"></div>
      <img
        src="https://mstein11.github.io/CoronaHeroWebsite/img/potato.PNG"
        style={{
          height: 200
        }}
        alt="potato"
      />
      <Typography variant="h4" component="h1">
        Potato Games
      </Typography>
      <div className="flex-1"></div>
      <Button
        variant="contained"
        color="primary"
        style={btnStyle}
        href={gameUrl(newGameId)}
      >
        New Game
      </Button>
      {/* <Button
        variant="outlined"
        color="primary"
        style={btnStyle}
        href={gameUrl(newGameId)}
      >
        Join Game
      </Button> */}
      <div className="flex-1"></div>
    </div>
  )
}
