import React, { useState, useEffect } from 'react'
import { Chip } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { GameSettingsDialog } from './GameSettingsDialog'
import { useGameSession } from '../service/game-session.hooks'

export interface GameChipProps {
  gameId: string
}

export const GameChip = (props: GameChipProps) => {
  const { gameId } = props

  const [gameSession, loading] = useGameSession(gameId)

  // Auto Open
  useEffect(() => {
    if (!loading && !gameSession) {
      setOpen(true)
    }
  }, [gameSession, loading])

  const [open, setOpen] = useState(false)

  const onClick = () => {
    setOpen(true)
  }

  const label = `game/${gameId}`

  return (
    <>
      <Chip
        // avatar={<Avatar>#</Avatar>}
        label={label}
        variant="outlined"
        color="primary"
        clickable
        onClick={onClick}
        onDelete={onClick}
        deleteIcon={<ExpandMore />}
      />
      <GameSettingsDialog
        gameId={gameId}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  )
}
