import React from 'react'
import { GameTop } from './GameTop'
import { useGameMode } from '../service/game-session.hooks'
import { useParams } from 'react-router-dom'

export const GameMain = () => {
  const { gameId = 'no-game-id' } = useParams()
  const gameMode = useGameMode(gameId)

  const gameModeComp = gameMode?.component || <div></div>

  return (
    <div
      className="flex-col"
      style={{
        minHeight: 'calc(100vh - 64px - 16px)',
        justifyContent: 'space-between',
      }}
    >
      <GameTop />
      {gameModeComp}
    </div>
  )
}
