import React, { useState } from 'react'
import {
  CardActionArea,
  CardActions,
  Button,
  Typography,
} from '@material-ui/core'
import { RepeatRounded } from '@material-ui/icons'
import { PlayingCard } from '../models/playing-card.model'
import { useUid } from '../service/auth.hooks'
import { setPlayingCard } from '../service/playing-cards.hooks'
import _ from 'lodash'
import { SimpleCardStack } from './SimpleCardStack'

export const PlayingCardStack = ({
  stackId = '',
  allCards = [] as PlayingCard[],
  enabled = true,
  cardHolding = undefined as PlayingCard | undefined,
}) => {
  const [uid] = useUid()
  const cards = allCards
    .filter((card) => card.stackId === stackId)
    .sort((a, b) => a.position - b.position)

  const [shuffling, setShuffling] = useState(false)

  const canDraw = () => cards.length > 0

  const actionText = () => {
    if (enabled) {
      if (cardHolding) return 'PLACE'
      if (canDraw()) return 'DRAW'
    }
    return ''
  }

  const mainAction = async () => {
    if (cardHolding) {
      await setPlayingCard({
        ...cardHolding,
        stackId,
        holdByUserId: '',
        position: cards.length,
      })
    } else {
      if (!canDraw()) return
      const card = cards[cards.length - 1]
      await setPlayingCard({
        ...card,
        stackId: '',
        holdByUserId: uid,
      })
    }
  }

  const shuffle = async () => {
    setShuffling(true)
    await Promise.all(
      _(cards)
        .shuffle()
        .map((card, position) => ({ ...card, position }))
        .map((card) => setPlayingCard(card))
        .value()
    )
    setTimeout(() => setShuffling(false), 500)
  }

  const stackTemplate = (
    <SimpleCardStack isEmpty={!canDraw()}>
      <CardActions className="flex-center">
        <Button
          size="small"
          onClick={shuffle}
          disabled={shuffling || !canDraw()}
          className={shuffling ? 'simple-spin' : ''}
        >
          {/* <Shuffle /> */}
          <RepeatRounded />
        </Button>
      </CardActions>
      <CardActionArea
        className="flex-center"
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        classes={{
          focusHighlight: 'opacity-0',
        }}
        onClick={mainAction}
        disabled={!enabled}
      >
        <div className="flex-1"></div>
        <Typography variant="h3">{cards.length}</Typography>
        <Typography>{cards.length === 1 ? 'Card' : 'Cards'}</Typography>
        <div className="flex-1"></div>
        <Typography>&nbsp;{actionText()}&nbsp;</Typography>
        <div className="flex-1"></div>
      </CardActionArea>
    </SimpleCardStack>
  )

  return stackTemplate
}
