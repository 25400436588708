import React, { useEffect, useState } from 'react'
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  // DialogContentText,
  DialogActions,
} from '@material-ui/core'
import { setUser, useUser } from '../service/user.hooks'
import { ColorSelect } from './ColorSelect'
import { defaultColor } from '../style/mui-theme'

export const UserEditDialog = ({
  open = false,
  onClose = () => {},
  userId = 'unknown-user-id',
  canCancel = true,
}) => {
  const [user] = useUser(userId)
  const [username, setUsername] = useState('')
  const [color, setColor] = useState(defaultColor)

  useEffect(() => {
    if (user?.color) setColor(user.color)
    if (user?.name) setUsername(user.name)
  }, [user])

  const canSubmit = !!username

  const submit = async (evt: any) => {
    evt?.preventDefault()
    if (!canSubmit) return
    console.log({ username })
    await setUser({
      ...user,
      color,
      id: userId,
      name: username,
      initials: username.substr(0, 2),
    })
    onClose()
  }

  const cancel = () => {
    if (!canCancel) return
    onClose()
  }

  return (
    <>
      <Dialog open={open} onClose={submit}>
        <form onSubmit={submit}>
          <DialogTitle>Player Settings</DialogTitle>
          <DialogContent>
            {/* <DialogContentText>Choose Your Username freely</DialogContentText> */}
            <TextField
              // autoFocus
              margin="dense"
              label="Your Name"
              variant="outlined"
              value={username}
              onChange={(evt) => setUsername(evt.target.value)}
              style={{ width: '100%' }}
            />
            <ColorSelect color={color} setColor={setColor} />
          </DialogContent>
          <DialogActions>
            <Button onClick={cancel} disabled={!canCancel}>
              Cancel
            </Button>
            <Button type="submit" color="primary" disabled={!canSubmit}>
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
