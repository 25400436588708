import React from 'react'
import './App.css'
import './service/firebase'
import { ThemeProvider, CssBaseline } from '@material-ui/core'
import { useThemeByPrimaryColor } from './style/mui-theme'
import { AppRouting } from './components/AppRouting'
import { BrowserRouter as Router } from 'react-router-dom'
import { waitForLogin } from './service/firebase'
import { useMyUser } from './service/user.hooks'

function App() {
  waitForLogin()
  const [user] = useMyUser()
  const theme = useThemeByPrimaryColor(user?.color)
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <AppRouting />
        </Router>
      </ThemeProvider>
    </>
  )
}

export default App
