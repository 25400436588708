import React, { PropsWithChildren } from 'react'
import { useTheme, Card } from '@material-ui/core'
import styled from 'styled-components'

interface SimpleCardStackProps {
  isEmpty: boolean
}

export const SimpleCardStack = (
  props: PropsWithChildren<SimpleCardStackProps>
) => {
  const theme = useTheme()
  const emptyColor = theme.palette.text.disabled
  const cardStyle: React.CSSProperties = props.isEmpty
    ? {
        border: `2px dashed ${emptyColor}`,
        color: emptyColor,
        background: 'none',
      }
    : {}

  return (
    <Card
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        border: `2px dashed transparent`,
        ...cardStyle,
      }}
    >
      {props.children}
    </Card>
  )
}

export const SimpleCardStackGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(var(--grid-cols, 2), 1fr);
  grid-gap: 8px;
  margin: 8px;
`
