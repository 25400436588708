import React, { useState, useRef } from 'react'
import {
  TextField,
  InputAdornment,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import { Done, FileCopy } from '@material-ui/icons'

export const CopyUrl = () => {
  const url = window.location.href
  const [copied, setCopied] = useState(false)
  const copy = () => {
    const input = (inputRef?.current as any) as HTMLInputElement
    input.focus()
    input.select()
    document.execCommand('copy')
    setCopied(true)
  }
  const inputRef = useRef()
  return (
    <TextField
      variant="outlined"
      label="Copy Url to share this Game Session"
      value={url}
      inputRef={inputRef}
      onClick={copy}
      onBlur={() => setCopied(false)}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <InputAdornment position="start">
            <Tooltip title={copied ? 'copied!' : 'copy'}>
              <IconButton edge="end">
                {copied ? <Done /> : <FileCopy />}
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
    />
  )
}
