import { useAuthState } from 'react-firebase-hooks/auth'
import { firebaseApp } from './firebase'

export const useAuth = () => {
  return useAuthState(firebaseApp.auth())
}

export const useUid = (): [string, boolean, any] => {
  const [user, loading, error] = useAuth()
  return [user?.uid as string, loading, error]
}
