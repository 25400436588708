import { collectionRef, useDocumentDataBetter, setDoc } from './firebase'
import { GameSession } from '../models/game-session.model'
import _ from 'lodash'
import { gameModes } from '../static/game-modes.static'

export const gameSessionCollectionRef = () => collectionRef(['games'])
export const gameSessionRef = (gameId: string) =>
  gameSessionCollectionRef().doc(gameId)

export const useGameSession = (gameId: string) => {
  const ref = gameSessionRef(gameId)
  return useDocumentDataBetter<GameSession>(ref)
}

export const useGameMode = (gameId: string) => {
  const [gameSession] = useGameSession(gameId)
  if (!gameSession) return null
  const gameModeName = gameSession?.gameMode
  const gameMode = _.find(gameModes, (gm) => gm.id === gameModeName)
  return gameMode || gameModes[0]
}

export const setGameSession = (gameSession: GameSession) => {
  return setDoc(gameSessionCollectionRef(), gameSession)
}
