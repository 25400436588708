import {
  collectionRef,
  setDoc,
  useCollectionDataBetter,
  deleteDoc,
  useDocumentDataBetter,
  deleteCollection,
} from './firebase'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { PlayingCard } from '../models/playing-card.model'
import { useEffect, useState } from 'react'

export const playingCardCollectionRef = (gameId: string) =>
  collectionRef(['games', gameId, 'playing-cards'])
export const playingCardRef = (gameId: string, cardId: string) =>
  playingCardCollectionRef(gameId).doc(cardId)

export const usePlayingCardsOld = (gameId: string) => {
  const ref = playingCardCollectionRef(gameId)
  return useCollectionData<PlayingCard>(ref)
}

export const usePlayingCards = (gameId: string) => {
  const [ref, setRef] = useState<any>()
  useEffect(() => {
    setRef(playingCardCollectionRef(gameId))
  }, [gameId])
  return useCollectionDataBetter<PlayingCard>(ref)
}

export const usePlayingCard = (gameId: string, cardId: string) => {
  return useDocumentDataBetter<PlayingCard>(playingCardRef(gameId, cardId))
}

export const setPlayingCard = async (card: Partial<PlayingCard>) => {
  if (!card.gameId) throw new Error('No GameId in Card')
  return setDoc(playingCardCollectionRef(card.gameId), card)
}

export const deletePlayingCard = async (card: PlayingCard) => {
  return deleteDoc(playingCardCollectionRef(card.gameId), card.id)
}

export const deletePlayingCards = async (gameId: string) => {
  return deleteCollection(playingCardCollectionRef(gameId))
}
