import React, { useState, useEffect } from 'react'
import { Button, ThemeProvider } from '@material-ui/core'
import { Settings } from '@material-ui/icons'
import { UserEditDialog } from './UserEditDialog'
import { useUser } from '../service/user.hooks'
import { User } from '../models/user.model'
import { useThemeByPrimaryColor } from '../style/mui-theme'

export const PlayerAvatar = ({
  size = 32,
  userId = 'unknown-user-id',
  editable = false,
  highlighted = false,
  style = {},
  secondaryAction = (user: User) => {},
}) => {
  const [open, setOpen] = useState(false)
  const [canCancel, setCanCancel] = useState(true)
  const [user, loading] = useUser(userId)

  const defaultInitials = `??`
  // const defaultInitials = `${userId.substr(0, 2)}`
  const initials = user?.initials || defaultInitials

  const onClick = () => {
    if (editable) {
      setOpen(true)
    } else {
      secondaryAction(user as User)
    }
  }

  const close = () => {
    setCanCancel(true)
    setOpen(false)
  }

  useEffect(() => {
    if (!editable) return
    if (!loading && !user) {
      setCanCancel(false)
      setOpen(true)
    }
  }, [editable, loading, user])

  const theme = useThemeByPrimaryColor(user?.color)

  return (
    <>
      <ThemeProvider theme={theme}>
        <Button
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: editable ? 'space-between' : 'center',
            // border: `1px solid ${color}`,
            // color,
            height: size,
            width: editable ? size * 2 : size,
            borderRadius: size / 5,
            fontSize: size / 2,
            textTransform: 'none',
            padding: '0 6px',
            minWidth: 0,
            ...style,
          }}
          onClick={onClick}
          variant={highlighted ? 'contained' : 'outlined'}
          color="primary"
        >
          <span style={{ whiteSpace: 'nowrap' }}>{initials}</span>
          {editable && <Settings />}
        </Button>
        <UserEditDialog
          userId={userId}
          open={open}
          onClose={close}
          canCancel={canCancel}
        />
      </ThemeProvider>
    </>
  )
}
