import React from 'react'
import { GamePlayerWithCard } from '../../models/game-players.model'
import { PlayerAvatar } from '../PlayerAvatar'
import { SimpleCardStack } from '../SimpleCardStack'
import { CardActionArea, Typography } from '@material-ui/core'
import { PlayingCard } from '../../models/playing-card.model'
import { setPlayingCard } from '../../service/playing-cards.hooks'
import { useUid } from '../../service/auth.hooks'

interface PlayerStackProps {
  gamePlayer: GamePlayerWithCard
  enabled: boolean
  isMe: boolean
  cardHolding?: PlayingCard
}

export const PlayerStack = ({
  gamePlayer,
  enabled,
  cardHolding,
  isMe,
}: PlayerStackProps) => {
  const [uid] = useUid()
  const pinnedCard = gamePlayer.pinnedCard

  const mainAction = () => {
    if (!enabled) return
    if (cardHolding && !pinnedCard) {
      setPlayingCard({
        ...cardHolding,
        holdByUserId: '',
        pinnedToUserId: gamePlayer.id,
      })
    }
    if (!cardHolding && pinnedCard) {
      if (isMe) {
        if (!window.confirm('Really pick up and reveal your own card?')) return
      }
      setPlayingCard({
        ...pinnedCard,
        holdByUserId: uid,
        pinnedToUserId: '',
      })
    }
  }

  const textShown = isMe ? '???' : pinnedCard?.text

  return (
    <SimpleCardStack isEmpty={!pinnedCard}>
      <CardActionArea
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 8,
        }}
        classes={{
          focusHighlight: 'opacity-0',
        }}
        onClick={mainAction}
        disabled={!enabled}
      >
        <PlayerAvatar userId={gamePlayer.id} highlighted={!!pinnedCard} />
        <Typography
          variant="body1"
          style={{
            textAlign: 'center',
            whiteSpace: 'pre-wrap',
            lineHeight: '1.1875em',
            flex: 1,
            marginTop: 8,
          }}
        >
          {!!pinnedCard && textShown}
        </Typography>
      </CardActionArea>
    </SimpleCardStack>
  )
}
