import { GuessMyCard } from '../components/guess-my-card/guess-my-card'
import React from 'react'
import { WhoAmI } from '../components/who-am-i/who-am-i'

export interface GameMode {
  id: string
  label: string
  component: JSX.Element
}

export const gameModes: GameMode[] = [
  {
    id: 'guess-my-card',
    label: 'guess-my-card',
    component: <GuessMyCard />,
  },
  {
    id: 'who-am-i',
    label: 'who-am-i',
    component: <WhoAmI />,
  },
]
