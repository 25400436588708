import React from 'react'
import { GameChip } from './GameChip'
import { PlayerAvatar } from './PlayerAvatar'
import { useUid } from '../service/auth.hooks'
import { useParams } from 'react-router-dom'

export const GameTop = () => {
  const { gameId = 'no-game-id' } = useParams()
  const [uid] = useUid()

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 8,
      }}
    >
      {/* <IconButton color="primary" href="/">
          <ArrowBack />
        </IconButton> */}
      <GameChip gameId={gameId} />
      <PlayerAvatar
        userId={uid}
        highlighted={
          // !!uid
          // !!cardHolding
          false
        }
        editable={true}
      />
    </div>
  )
}
