import { collectionRef, setDoc, useDocumentDataBetter } from './firebase'
import { User } from '../models/user.model'
import { useUid } from './auth.hooks'

export const userCollectionRef = () => collectionRef(['users'])
export const userRef = (userId: string) => userCollectionRef().doc(userId)

export const useUser = (userId: string = 'unknown-user-id') => {
  const ref = userRef(userId)
  return useDocumentDataBetter<User>(ref)
}

export const useMyUser = (): [User | undefined, boolean, any] => {
  const [uid, loading, error] = useUid()
  const userResult = useUser(uid as string)
  if (!uid || loading || error) return [undefined, loading as boolean, error]
  const [user, b, c] = userResult
  return [{ id: uid as string, ...user } as User, b, c]
}

export const setUser = async (user: User) => {
  return setDoc(userCollectionRef(), user)
}
