import React from 'react'
import { Button, ThemeProvider } from '@material-ui/core'
import { colors, themeByPrimaryColor, defaultColor } from '../style/mui-theme'
import { SimpleCardStackGrid } from './SimpleCardStack'

const selections = colors.map((c) => {
  const theme = themeByPrimaryColor(c.color)

  return {
    ...c,
    theme,
  }
})

export const ColorSelect = ({
  color = defaultColor,
  setColor = (color: string) => {},
}) => {
  // const [color, setColor] = useState(defaultColor)

  const localSelections = selections.map((s) => ({
    ...s,
    active: s.color === color,
  }))

  return (
    <div style={{ margin: '16px 0 0' }}>
      <div
        style={{
          color: 'rgba(255, 255, 255, 0.7)',
          fontSize: 12,
          margin: '0 12px 4px',
        }}
      >
        Your Color
      </div>
      <SimpleCardStackGrid style={{ margin: 0, '--grid-cols': 3 } as any}>
        {localSelections.map((s, idx) => (
          <ThemeProvider theme={s.theme} key={s.color}>
            <Button
              color="primary"
              variant={s.active ? 'contained' : 'outlined'}
              onClick={() => setColor(s.color)}
            >
              {s.label}
            </Button>
          </ThemeProvider>
        ))}
      </SimpleCardStackGrid>
    </div>
  )
}
