import React from 'react'
import { useCardGameBasics } from '../../util/card-game.hooks'
import { GameCardHolding } from '../GameCardHolding'
import { SimpleCardStackGrid } from '../SimpleCardStack'
import { PlayerStack } from './PlayerStack'

export const WhoAmI = () => {
  const {
    uid,
    gameId,
    editing,
    setEditing,
    cardHolding,
    gamePlayers,
  } = useCardGameBasics()

  return (
    <>
      <SimpleCardStackGrid>
        {gamePlayers.map((gamePlayer) => (
          <PlayerStack
            key={gamePlayer.id}
            gamePlayer={gamePlayer}
            enabled={!editing}
            isMe={gamePlayer.id === uid}
            cardHolding={cardHolding}
          />
        ))}
      </SimpleCardStackGrid>
      <GameCardHolding
        gameId={gameId}
        cardHolding={cardHolding}
        editing={editing}
        setEditing={(e) => setEditing(e)}
      />
    </>
  )
}
