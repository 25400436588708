import React from 'react'
import { useGameSession, setGameSession } from '../service/game-session.hooks'
import { gameModes } from '../static/game-modes.static'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'

export const GameSettingGameMode = ({ gameId }: { gameId: string }) => {
  const [gameSession] = useGameSession(gameId)

  const setGameMode = (gameMode: string) => {
    setGameSession({
      ...gameSession,
      id: gameId,
      gameMode,
    })
  }

  return (
    <div className="flex-col">
      <div
        style={{
          color: 'rgba(255, 255, 255, 0.7)',
          fontSize: 12,
          margin: '0 12px 4px',
        }}
      >
        Game Mode
      </div>
      <ToggleButtonGroup
        style={{ marginBottom: 32 }}
        value={gameSession?.gameMode}
        onChange={(evt, gameMode) => setGameMode(gameMode)}
        exclusive
      >
        {gameModes.map((gameMode) => (
          <ToggleButton
            key={gameMode.id}
            value={gameMode.id}
            style={{ flex: 1 }}
          >
            {gameMode.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  )
}
